<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4 grey lighten-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="subtitle-1 ma-0">
          Escolha um médico solicitante abaixo para editar ou excluir.
        </p>
        <v-btn
          v-if="medicoCriar"
          small
          depressed
          color="fmq_gray"
          dark
          to="/medico-solicitante-criar"
          >Novo Médico</v-btn
        >
      </div>
      <MedicosSolicitantesTabela
        :headers="headers"
        :itens="itens"
        :total="total"
        :pageText="pageText"
        :pageNumber="page"
        :loading="loading"
        @handleFilter="filter"
        @excluir="excluirModal"
      >
        <template v-slot:nome>
          <v-text-field
            v-model="formData.nome"
            placeholder="Digite o nome"
            solo
            flat
            dense
            :hide-details="true"
            @input="buscar(formData)"
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:[`especialidades`]>
          <v-autocomplete
            v-model="formData.especialidadesId"
            :items="listEspecialidades"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Digite a especialidade"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:crm>
          <v-text-field
            v-model="formData.crm"
            placeholder="Digite o CRM"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              page = 1;
              buscar(formData);
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:email>
          <v-text-field
            v-model="formData.email"
            placeholder="Digite o e-mail"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              page = 1;
              buscar(formData);
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:aceiteTermosUso>
          <v-select
            v-model="formData.aceiteTermosUso"
            placeholder="Selecione"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              page = 1;
              buscar(formData);
            "
            :clearable="true"
            :items="listTermosUso"
          >
          </v-select>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </MedicosSolicitantesTabela>
      <AlertConfirmation
        :dialog="dialog"
        :dialogMessage="dialogMessage"
        @close="dialog = false"
        @accept="excluir"
      />
      <AlertSuccess
        :dialog="success"
        dialogMessage="Médico excluido com sucesso"
        @close="
          buscar(formData);
          success = false;
          clear();
        "
      />
      <AlertError :alertError="error" :messageError="messageError" />
    </div>
  </div>
</template>

<script>
import MedicosSolicitantesTabela from "@/components/medicos-solicitantes/MedicosSolicitantesTabela.vue";
import {
  buscarMedicoSolicitante,
  excluirMedicoSolicitante,
} from "@/services/medico-solicitante.js";
import { listarEspecialidade } from "@/services/especialidades.js";
export default {
  name: "MedicosSolicitantes",
  components: { MedicosSolicitantesTabela },
  data: () => ({
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: 15,
      nome: null,
      especialidadesId: null,
      crm: null,
      email: null,
      aceiteTermosUso: null,
    },
    headers: [
      { text: "Nome do Médico", value: "nome" },
      { text: "Especialidade(s)", value: "especialidades", sortable: false },
      { text: "CRM", value: "crm" },
      { text: "Email", value: "email" },
      { text: "Aceite de termos", value: "aceiteTermosUso" },
      { text: "Ações", value: "id", sortable: false },
    ],
    page: 1,
    itens: [],
    total: null,
    pageText: null,
    loading: false,
    breadcrumbs: [
      {
        text: "Médicos Solicitantes",
        disabled: true,
        to: "",
      },
    ],
    listEspecialidades: [],
    listTermosUso: ["Sim", "Não"],
    dialog: false,
    dialogMessage: null,
    medicoId: null,
    error: false,
    success: false,
    messageError: null,
  }),
  created() {
    if (this.$store.state.medicosSearch.length) {
      this.formData = { ...this.$store.state.medicosSearch[0] };
    }
    this.buscar(this.formData);
    this.listEspecialidade();
  },
  methods: {
    async buscar(payload) {
      this.itens = [];
      this.$store.dispatch("setMedicosSearch", payload);
      try {
        this.loading = true;
        const resp = await buscarMedicoSolicitante(this.page, payload);
        this.itens = resp.data.data;
        this.total = resp.data.total;
        this.pageText = `${resp.data.from}-${resp.data.to} de ${resp.data.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.$store.dispatch("setMedicosSearch", {});
      this.formData.nome = null;
      this.formData.crm = null;
      this.formData.especialidadesId = null;
      this.formData.email = null;
      this.formData.aceiteTermosUso = null;
      this.page = 1;
      this.buscar(this.formData);
    },
    search() {
      this.page = 1;
      this.buscar(this.formData);
    },
    filter(event) {
      this.$store.dispatch("setMedicosSearch", this.formData);
      this.formData.orderBy = event.orderBy;
      this.formData.orderSorted = event.orderSorted;
      this.formData.perPage = event.perPage;
      this.page = event.page;
      this.buscar(this.formData);
    },
    async listEspecialidade() {
      const resp = await listarEspecialidade();
      this.listEspecialidades = resp.data;
    },
    async excluir() {
      this.error = false;
      this.sucess = false;
      try {
        await excluirMedicoSolicitante(this.medicoId).then(() => {
          this.dialog = false;
          this.success = true;
          this.page = 1;
        });
      } catch (e) {
        this.dialog = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    excluirModal(item) {
      this.dialogMessage = `Tem certeza que deseja excluir ${item.nome}?`;
      this.dialog = true;
      this.medicoId = item.id;
    },
  },
  computed: {
    medicoCriar() {
      return this.$store.state.user.data.permissoes.includes("medicoSolicitante.criar");
    },
  },
};
</script>
